import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

try{
  window.dataLayer = window.dataLayer || [];
  
  function gtag(){window.dataLayer.push(arguments);}
  
  window.gtag = gtag;
  
  gtag('js', new Date());
}catch(ex){
  console.log("Failed to initialize main gtag", ex)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
