
export default class DataLayerService {

    initializeTagManager(containerId) {
        try{
            console.log('!!!!!!!!!!! GTM initializing with container id: ', containerId)
            (function (windowObj, documentObj, scriptTag, dataLayerName, containerId) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            
            var firstScript = documentObj.getElementsByTagName(scriptTag)[0],
                newScript = documentObj.createElement(scriptTag),
                dataLayerParam = dataLayerName !== 'dataLayer' ? '&l=' + dataLayerName : '';
            
            newScript.async = true;
            newScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + containerId + dataLayerParam;
            firstScript.parentNode.insertBefore(newScript, firstScript);
            })(window, document, 'script', 'dataLayer', containerId);

            console.log('!!!!!!!!!!! GTM initialized with container id: ', containerId)
        }catch(e){
            console.log(e)
        }
    }
    
    setupGTag(gtagKeyList){
        console.log('!!!!!!!!!!! GTM initializing setup with containers: ', gtagKeyList)
        gtagKeyList.map(key => {
            this.initializeTagManager(key)
            window.gtag("config", key, {
                page_title: window.location.hash,
                page_path: window.location.hash,
            })
    })


    

    }
    setupFbq(keyList){
        keyList.map(key => window.fbq('init', key)) 
        window.fbq('track', 'PageView')
    }
    

    sendGtagEvent(event_name, category, payload = {}){
        window.gtag("event", event_name, {
            event_category: category,
            event_label: event_name,
            value: payload
        })
    }

    sendGTMPurchaseEvent(payload){
        const { products, total, coupon, transaction_id, discount } = payload
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id,
                value: total,
                tax: 0,
                shipping: 0,
                currency: "BRL",
                coupon,
                items: products.map((product, index) => {
                    return {
                        item_id: product.sku,
                        item_name: product.name,
                        // affiliation: "Google Merchandise Store",
                        coupon,
                        discount,
                        index: index,
                        // item_brand: "Google",
                        // item_category: "Apparel",
                        // item_category2: "Adult",
                        // item_category3: "Shirts",
                        // item_category4: "Crew",
                        // item_category5: "Short sleeve",
                        // item_list_id: "related_products",
                        // item_list_name: "Related Products",
                        // item_variant: "green",
                        location_id: product.id,
                        price: product.value,
                        quantity: product.quantity
                    }
                })
            }
        });
    }

    sendGTMCheckoutEvent(payload){
        const { products, total } = payload
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: "BRL",
                value: total,
                coupon: null,
                items: products.map((product, index) => {
                    return {
                        item_id: product.sku,
                        item_name: product.name,
                        affiliation: null,
                        coupon: null,
                        discount: 0,
                        index,
                        // item_brand: "Google",
                        // item_category: "Apparel",
                        // item_category2: "Adult",
                        // item_category3: "Shirts",
                        // item_category4: "Crew",
                        // item_category5: "Short sleeve",
                        // item_list_id: "related_products",
                        // item_list_name: "Related Products",
                        // item_variant: "green",
                        // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                        price: product.value,
                        quantity: product.quantity
                    }
                })
            }
        });
    }

    pushCheckoutMetric(event_name, payload){
        const timestamp = Date.now()

        if(window.gtag){
            // this.sendGTMCheckoutEvent({
            //     products: [{
            //         name: payload.product,
            //         sku: payload.id,
            //         value: payload.value,
            //         quantity: 1
            //     }],
            //     total: payload.value
            // })
            this.sendGtagEvent(event_name, "checkout", JSON.stringify(payload))
        }

        if(window.fbq){
            let event = event_name
            switch(event_name){
                case "payment_intent":
                    event = "initiatecheckout"
                    break;
                case "checkout_finished":
                    event = "purchase"
                break;
                default:
                    event = event_name
            }
            window.fbq('trackCustom', event, payload,{eventID: timestamp});
        }
    }

    pushCheckoutView(event_name, payload){
        const timestamp = Date.now()

        if(window.gtag)
            this.sendGtagEvent(event_name, "checkout", JSON.stringify(payload))

        if(window.fbq)
            window.fbq('InitiateCheckout', event_name, {eventID: timestamp});
    }

    pushConversion(payload, conversion_id){
        const timestamp = Date.now()
        if(window.gtag)
            conversion_id.map(id => window.gtag('event', 'conversion', {
                'send_to': id,
                'transaction_id': payload.transaction_id,
                'value': payload.value,
                'currency': 'BRL'
            }))

        if(window.fbq)
            window.fbq('track', 'Purchase', {currency: "BRL", value: payload.value}, {eventID: timestamp});

    }

}